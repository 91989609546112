<script setup lang="ts">
// definePageMeta({
//     auth: false,
// })
import { useRouteQuery } from "@vueuse/router"

const { loading, start, stop } = await useLoading()
const cardHorizontal = true
const cardOrientation = ref(cardHorizontal)
const newsletters = ref([])
const categories = ref([])
const pagination = ref({
	page: 1,
	perPage: 10,
	total: 0,
	items: 0,
	totalPages: 0,
	from: 0,
	to: 0,
})
const perPage = ref(10)
const page = ref(1)
const total = ref(0)
const items = ref(0)
const order = ref({ field: "createdAt", order: "desc" })
const search = useRouteQuery("search")
const query = ref(search.value)
const params = computed(() => {
	const p = {
		pagination: { page: page.value, perPage: perPage.value },
		order: order.value,
		filter: {},
		query: query.value,
	}
	if (categories.value.length >= 0) {
		p.filter = { categories: categories.value }
	}
	return p
})

const { data, execute, refresh, pending } = await useFetch("/api/newsletters", {
	method: "GET",
	params,
})
newsletters.value = data?.value.data
total.value = data?.value.pagination.total
items.value = data?.value.pagination.items
pagination.value = data?.value.pagination

watch(pending, (value) => {
	if (value) {
		scrollTo(0, 0)
		start()
	} else {
		stop()
	}
})
watch(data, (value) => {
	newsletters.value = data?.value.data
	total.value = data?.value.pagination.total
	items.value = data?.value.pagination.items
	pagination.value = data?.value.pagination
})
const onCategoriesUpdated = async (c: any[]) => {
	categories.value = c.map((item) => item.slug) || null
}
const searchNewsletter = () => {
	query.value = search.value
}

watch(search, (value) => {
	if (value === "") { searchNewsletter() }
})

const orderOptions = ref([
	{
		value: { field: "createdAt", order: "desc" },
		label: "Los mas nuevos primero",
	},
	{
		value: { field: "createdAt", order: "asc" },
		label: "Los mas antiguos primero",
	},
])

const screenWidth = computed(() => {
	if (process.client) {
		let width = window.innerWidth
		return width
	}
})

watch(
	screenWidth,
	(value) => {
		if (value !== undefined && value < 768 && cardOrientation.value) {
			cardOrientation.value = false
		}
	},
	{ deep: true, immediate: true }
)

const nextPage = () => {
	page.value = page.value + 1
}
const previousPage = () => {
	page.value = page.value - 1
}
const trackAdd = () => {
	const route = useRoute()
	useTrackEvent('Ad Clicked', {
		props: { id: 'ad-chus-letter', page: route.path }
	})
}
</script>

<template>
	<div>
		<PageHeader />
		<div class="mt-4 max-w-7xl mx-auto px-4">
			<div class="flex flex-col-reverse md:grid md:grid-cols-4">
				<div class="col-span-1">
					<AsideCategories @update:model-value="onCategoriesUpdated" class="sticky top-20" />
				</div>

				<main class="col-span-3">
					<form
						@submit.prevent="searchNewsletter"
						class="sm:w-2/3 md:w-full flex items-center justify-center gap-x-2 sticky top-20 bg-white/20 backdrop-blur-2xl py-4 px-8 rounded-b-2xl z-10"
					>
						<FormInputText type="search" class="flex-1" v-model="search" />
						<ButtonBase class="mt-2" type="submit">
							<Icon name="heroicons:magnifying-glass" class="h-5 w-5 mr-2" />
							<span>Buscar</span>
						</ButtonBase>
					</form>

					<section>
						<div>
							<ClientOnly>
								<div class="flex justify-between mt-8 mb-3 text-xl">
									<div class="text-gray-500 text-xs">
										Mostrando {{ items }} de {{ total }} resultados
									</div>
									<div class="text-gray-500 text-xs">
										<select v-model="order" class="bg-white">
											<option v-for="option in orderOptions" :value="option.value">
												{{ option.label }}
											</option>
										</select>
									</div>
								</div>
							</ClientOnly>

							<div v-if="loading">
								<div
									v-for="i in [1, 2, 3, 4, 5, 6]"
									class="w-full mb-6 rounded-xl shadow-lg shadow-primary-50"
								>
									<div class="grid grid-cols-4">
										<div class="col-span-1 max-w-[350px] min-h-[280px] max-h-[300px]">
											<SkeletonLoader class="w-[230px] h-[280px]" />
										</div>
										<div class="col-span-3">
											<div>
												<div
													class="h-[250px] bg-white rounded-lg p-4 flex flex-col justify-between leading-normal"
												>
													<div class="mb-8">
														<div class="pb-2">
															<span class="flex item-center gap-x-2">
																<SkeletonLoader v-for="t in [1, 2, 3]" class="w-12 h-5" />
															</span>
														</div>
														<div>
															<SkeletonLoader class="w-44 h-5 my-2" />
															<SkeletonLoader class="w-64 h-3 my-2" />
															<SkeletonLoader class="w-32 h-3 mt-8" />
															<SkeletonLoader class="w-16 h-3 mt-5" />
															<SkeletonLoader class="w-32 h-3 mt-5" />
															<SkeletonLoader class="w-44 h-3 mt-5" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-else>
								<div
									v-if="newsletters.length > 0"
									:class="{
										'ltablet:grid-cols-3 grid gap-6 sm:grid-cols-2 lg:grid-cols-3': !cardHorizontal,
										'': cardHorizontal,
									}"
								>
									<div v-for="(newsletter, index) in newsletters" :key="index">
										<CardPreview
											:cardHorizontal="cardOrientation"
											v-bind="newsletter"
											:loading="loading"
										/>
									</div>
								</div>
								<div v-else>No hay newsletters con esos filtros</div>
							</div>
						</div>
					</section>
					<nav
						class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
						aria-label="Pagination"
					>
						<div class="hidden sm:block">
							<p class="text-sm text-gray-700">
								Mostrando
								<span class="font-medium">{{ pagination.items }}</span>
								de
								<span class="font-medium">{{ pagination.total }}</span>
								resultados
							</p>
						</div>
						<div class="flex flex-1 justify-between sm:justify-end">
							<button
								@click="previousPage"
								:disabled="pagination.page === 1"
								class="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:text-gray-200 disabled:bg-gray-200/40 disabled:ring-gray-200/40"
							>
								Anterior
							</button>
							<button
								@click="nextPage"
								:disabled="pagination.page === pagination.totalPages"
								class="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:text-gray-200 disabled:bg-gray-200/40 disabled:ring-gray-200/40"
							>
								Siguiente
							</button>
						</div>
					</nav>
				</main>
			</div>
		</div>
		<div class="text-center mt-36">
			<p class="text-sm text-gray-700">
				Powered by
			</p>
			<div class="flex justify-center">
				<a href="https://squinbox.com">
					<img src="/images/brand/logo-squinbox.png" alt="logo-squinbox" class="h-12">
				</a>
			</div>
		</div>
		<PageFooter />
	</div>
</template>
